.case-details__sidebar{
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

    .case-details__sidebar__image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--clr-grey);
        border-radius: 50%;
        width: 90px;
        height: 90px;
        position: relative;
        margin-top: 3px;
    }

    .case-details__sidebar__image-container__avatar { 
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
    }

    .case-details__sidebar__image-container-status-indicator {
        position: absolute;
        border: 4px solid var(--clr-background);
        right: -5px;
        top: -5px;
        background-repeat: no-repeat;
        background-position: 6px 4px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    span.case-details__sidebar__status-indicator-complete {
        color: var(--clr-green);
    }

    div.case-details__sidebar__status-indicator-complete {
        background-color: var(--clr-green);
    }

    .case-details__sidebar__firm-info {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
    }

    .case-details__sidebar__firm-info__case-number {
        display: block;
        color: var(--clr-darkgrey);
        font-weight: bold;
        text-align: center;
    }

    .case-details__sidebar__firm-info__position-company, .case-details__sidebar__firm-info__position-company-link {
        font-size: 14px;
        color: var(--clr-midgrey);
        margin-top: 5px;
        text-align: center;
        text-decoration: none;
        font-weight: 500;
    }

    .case-details__sidebar__section {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0 0 15px;
    }

        .case-details__sidebar__section__title {
            font-size: 14px;
            color: var(--clr-midgrey);
            font-weight: bold;
            text-transform: uppercase;
            line-height: 14px;
            display: flex;
            width: 100%;
            margin-bottom: 15px;
        }

        .case-details__sidebar__section__title img {
            margin-right: 10px;
        }