 .register-container__actions {
    display: flex;
    justify-content: flex-end;
}

.register-container__fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.register-container__fields::after {
    display: block;
	content: '';
	width: 31%;
}

.register-container__input {
    width: 31%;
}

.register-container__checkbox {
    width: 100%;
}


/*************** AZAMI ***************/

.register-container-azami {
    width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.register-container__checkbox-checkmark .checkmark {
    line-height: 1;
}

    
