.form-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;

    span.label {
        margin-bottom: 8px;
        margin-left: 5px;
        font-size: 13px;
        font-weight: 500;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        color: var(--theme-clr-base-dark);

        .required {
            padding-left: 5px;
            vertical-align: middle;
            color: var(--theme-clr-secondary);
            cursor: pointer;
            line-height: 0px;
            font-size: 18px;
        }

        img {
            margin-left: 3px;
        }
    }

    .description {
        padding: 0 0 5px 5px;
        font-size: 14px;
        font-style: italic;
        color: var(--clr-midgrey);
    }

    .input {
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-radius: var(--theme-input-border-radius);
        padding: 8px;
        outline: none;
        height: 37px;
        transition: all .3s ease-in-out;
        color: var(--theme-clr-base-mid6);
    
        &.primary {
            border-color: var(--theme-input-border-color);

            &:focus {
                border-color: var(--theme-clr-primary);
                box-shadow: var(--theme-clr-shadow-primary);
            }
        }

        &.secondary {
            border-color: var(--theme-input-border-color);

            &:focus {
                border-color: var(--theme-clr-secondary);
                box-shadow: var(--theme-clr-shadow-secondary);
            }
        }
    
        &.dark {
            background-color: var(--theme-input-dark-background-color);
            border-color: transparent;
            color: var(--theme-clr-base-dark);

            &:focus {
                /* border-color: var(--theme-clr-primary); */
                border-color: var(--theme-input-dark-border-color);
                box-shadow: var(--theme-clr-shadow-inner);
            }
        }
    
        &:disabled {
            opacity: .5;
            box-shadow: none !important;
        }
    
        &::-webkit-calendar-picker-indicator {
            position: absolute;
            right: 8px;
        }

    }

    &.error {
        .input {
            border-color: var(--theme-clr-palette-red) !important;
            box-shadow: var(--theme-clr-shadow-error);
        }

        .error-label {
            font-size: 12px;
            color: var(--theme-clr-palette-red);
            text-align: right;
            display: block;
            position: absolute;
            top: 0;
            right: 5px;
        }
    }

    .preview {
        cursor: pointer;
        font-size: 12px;
        color: var(--theme-input-preview-color);
        text-align: right;
        display: block;
        position: absolute;
        top: 0;
        right: 5px;
    }   
    
    .description:has(~ .form-input__input:focus) {
        color: inherit;
    }

    .subtitle {
        font-size: 12px;
        color: var(--theme-clr-base-mid3);
        padding: 0 5px 5px 5px;
    }
}

