.firm-sidebar {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    align-items: center;
}

    .firm-sidebar__image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        position: relative;
        margin-top: 3px;
    }

        .firm-sidebar__image-container__avatar {
            width: 90px;
            height: 90px;
            background-color: var(--clr-grey);
            border: none;
            border-radius: 50%;
            outline: none;
            object-fit: cover;
        }

        .firm-sidebar__image-container__btn-change-avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--clr-darkblue);
            position: absolute;
            border: 4px solid var(--clr-background);
            right: -5px;
            top: -5px;
            cursor: pointer;
            background-image: url(../../../assets/images/icons/ico_upload_avatar.svg);
            background-repeat: no-repeat;
            background-position: 6px 4px;
        }

    .firm-sidebar__firm-info {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
    }

        .firm-sidebar__firm-info__name {
            display: block;
            color: var(--clr-darkgrey);
            font-weight: bold;
            text-align: center;
        }

        .firm-sidebar__firm-info__position-company {
            font-size: 14px;
            color: var(--clr-midgrey);
            margin-top: 5px;
            text-align: center;
        }

        .firm-sidebar__firm-info__company-website {
            font-size: 14px;
            color: var(--clr-blue);
            margin-top: 5px;
            display: inline-block;
            width: 100%;
            text-align: center;
        }

        .firm-sidebar__firm-info__company-website:hover {
            text-decoration: none;
        }

    .firm-sidebar__section {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 0 0 15px;
    }

        .firm-sidebar__section__title {
            font-size: 14px;
            color: var(--clr-midgrey);
            font-weight: bold;
            text-transform: uppercase;
            line-height: 14px;
            display: flex;
            width: 100%;
            margin-bottom: 15px;
        }

            .firm-sidebar__section__title img {
                margin-right: 10px;
            }

        .firm-sidebar__section__body__social-links {
            background-color: var(--clr-grey);
            border-radius: var(--radius);
            padding: 15px;
        }

            .firm-sidebar__section__body__social-links li {
                margin-bottom: 10px;
            }

            .firm-sidebar__section__body__social-links li:last-of-type {
                margin-bottom: 0;
            }
        
        .firm-sidebar__uploader {
            width: 100%;
            padding: 30px 0 0 15px;
        }

    .firm-sidebar__section__body__social-links .twitter {
        color: #00ACEE;
    }

    .firm-sidebar__section__body__social-links .linkedin {
        color: #0E76A8;
    }

    .firm-sidebar__section__body__social-links .facebook {
        color: #3B5998;
    }

.firm-details-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

    .firm-details-content__header {
        width: 100%;
        padding: 15px;
    }

        .firm-details-content__header nav {
            width: 100%;
            background-color: var(--clr-azami-blue-glass);
            border-radius: var(--radius-small);
            padding: 5px 7px;
            display: flex;
            gap: 5px;
        }

            .firm-details-content__header nav a {
                color: var(--clr-azami-blue);
                text-decoration: none;
                font-size: 14px;
                display: inline-block;
                padding: 5px 8px;
                background-color: transparent;
                border-radius: var(--radius-small);
                text-transform: capitalize;
                transition: all .15s ease-in-out;
                position: relative;
            }

            .firm-details-content__header nav a.active {
                background-color: var(--clr-azami-blue);
                color: var(--clr-lightblue);
            }

            .firm-details-content__header nav a:hover {
                background-color: rgba(255, 255, 255, .5);
            }

            .firm-details-content__header nav a.active:hover {
                background-color: var(--clr-darkblue);
                color: var(--clr-lightblue);
            }

                .firm-details-content__header__update-indicator {
                    display: block;
                    position: absolute;
                    background-color: var(--clr-orange);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    top: -2px;
                    right: -2px;
                }
                
                .firm-details-content__header__error-indicator {
                    display: block;
                    position: absolute;
                    background-color: var(--clr-red);
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    top: -2px;
                    right: -2px;
                }

    .firm-details-content__body {
        width: 100%;
        padding: 0 30px 15px;
        display: flex;
        flex-grow: 1;
    }

    .firm-details-content__footer {
        width: 100%;
        height: 55px;
        background-color: var(--clr-grey);
        border-radius: 0 0 0 var(--radius);
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        gap: 10px;
    }    

/* ****************************************************************************************** */
/* *********************************** FIRM DETAILS MAIN ************************************ */
/* ****************************************************************************************** */

.firm-details-main {
    justify-content: space-between;
    gap: 44px;
    grid-gap: 44px;
    display: flex;
}

    .firm-details-main__left {
        flex: 2;
    }

    .firm-details-main__right {
        flex: 1;
    }

    .firm-details-main__row {
        display: flex;
        gap: 34px;
    }

        .firm-details-main__col {
            width: 265px;
            display: flex;
            flex-direction: column;
            flex: 1;
        }

            .firm-details-main__right .firm-details-main__col {
                width: 380px;
            }

            .firm-details-main__col__title {
                font-size: 16px;
                color: var(--clr-darkgrey);
            }

            .firm-details-main__col__input-list {
                padding: 15px 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

                .firm-details-main__col__input-list .number_of_attorneys {
                    width: 55%;
                }

                .firm-details-main__col__input-list #firm_size_container {
                    margin-left: 9px;
                }

                .firm-details-main__col__input-list .firm_size_label {
                    margin-bottom: 18px;
                }

                .firm-details-main__col__input-list .firm_size_text {
                    color: grey;
                }

                .firm-details-main__col__input-list .form-switch__pill {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: middle;
                }

                .firm-details-main__col__input-list .form-switch__label {
                    vertical-align: middle;
                }

                .firm-details-main__col__input-list .firm-family {

                }

                .firm-details-main__col__input-list .firm-family-missing {
                    opacity: .5;
                }

                .firm-details-main__col__input-list.vcenter {
                    align-items: center;
                }

                .firm-details-main__col__input-list .pricing-levels {
                    justify-content: space-between;
                    flex-direction: row;
                }

                .firm-details-main__col__input-list .pricing-levels select{
                    width: 50%;
                }

                .firm-details-main__col__input-list .pricing_levels_label {
                    margin-bottom: 10px;
                    font-size: 14px;
                }

                .firm-details-main__col__input-list .pricing-levels span {
                margin-bottom: auto;
                margin-top: auto;
                text-transform: none;
                font-weight: 400;
                font-size: 14px;
                }

                .firm-details-main__col textarea {
                    min-height: 120px;
                }
                
                .firm-details-main .firm-details-main__col__title {
                    text-decoration: underline;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 18px;
                    color: var(--clr-black);
                    margin: 5px 0;
                }

                .firm-details-main .form-input {
                    margin-bottom: 20px;
                }

                .firm-details-main .form-input__label,
                .firm-details-main .form-select__label {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14px;
                    text-align: left;
                    color: var(--clr-black-transparent50) !important;
                    margin: 0 0 3px;
                }

                .firm-details-main .form-input__input:disabled,
                .firm-details-main .form-select__input:disabled {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    padding: 0;
                    height: auto;
                }

/* ****************************************************************************************** */
/* ********************************* FIRM RECIPROCITY MAIN ********************************** */
/* ****************************************************************************************** */

.firm-details-reciprocity {
    width: 100%;
}

    .firm-details-reciprocity__ratio-points-switch {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
    }

        .firm-details-reciprocity__ratio-points-switch__label {
            font-size: 14px;
            display: block;
            padding-left: 8px;
        }

/* ****************************************************************************************** */
/* ************************************** FIRM POINTS *************************************** */
/* ****************************************************************************************** */

.firm-details-points {
    /* gap: 44px; */
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 350px;
}

    .firm-details-points__section-filters {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        grid-gap: 15px;
        gap: 15px;
    }

    .firm-details-points__section-header {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0;
    }

        .firm-details-points__section-header__title {
            display: flex;
            color: var(--clr-darkgrey);
            font-size: 16px;
            font-weight: bold;
        }

    .firm-details-points__section-body {
        margin-top: 15px;
    }

        .firm-details-points__section-body .adjusted-cell {
            display: flex;
            flex-direction: column;
        }

        .firm-details-points__section-body span.adjusted-date {
            font-size: 12px;
            color: rgb(185,188,182);
            margin-bottom: 2px;
        }

        .firm-details-points__section-body .points-status-cell-pending {
            color: var(--theme-clr-palette-red);
        }
    
        .firm-details-points__section-body .points-status-cell-approved {
            color: var(--theme-clr-palette-green);
        }
    
        .firm-details-points__section-body .points-status-cell-pending,
        .firm-details-points__section-body .points-status-cell-approved {
            text-transform: capitalize;
        }
        
        .firm-details-points__section-body .action,
        .firm-details-points__section-body .action:hover {
            background-repeat: no-repeat;
            background-color: transparent;
            border: none;
            width: 19px;
            height: 17px;
            display: inline-block;
            cursor: pointer;
        }
        .firm-details-points__section-body .action.adjust {
            background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
        }
        .firm-details-points__section-body .action.delete {
            background-image: url(../../../assets/images/icons/ico_btn_delete_lightgrey.svg);
        }
    
    .firm-details-points__section-footer {
       text-align: center;
    }

        .firm-details-points__section-footer a.client-report {
            background-color: rgb(229,229,229);
            color: rgb(150,150,150);
            text-decoration: none;
            padding: 5px 25px;
            font-size: 12px;
            margin: 10px auto;
            border-radius: 5px;
            text-transform: uppercase;
        }

    .adjustment-row{
        background-color: var(--clr-adjustment-table-row);
    }



    .adjust-firm-points-modal {
        width: 80%;
        background-color: var(--clr-white);
        overflow: hidden;
        border-radius: 10px;
        box-shadow: var(--shadow);
        /* padding: 20px; */
    }
    
        .adjust-firm-points-modal__header {
            background-color: var(--clr-darkblue);
            padding: 14px 16px;
            display: flex;
            align-items: center;
        }
    
            .adjust-firm-points-modal__header img {
                margin-right: 14px;
            }
    
            .adjust-firm-points-modal__header h4 {
                color: var(--clr-white);
            }
            
            .adjust-firm-points-modal__header button {
                margin-left: auto;
                background-color: transparent;
                outline: none;
                border: none;
                color: var(--clr-white);
                font-size: 20px;
                cursor: pointer;
                width: 20px;
            }
    
        .adjust-firm-points-modal__body {
            padding: 26px 16px;
            color: var(--clr-darkgrey);
            /* min-height: 150px; */
            max-height: 400px;
            overflow-y: auto;
        }
    
            .adjust-firm-points-modal__body__filters {
                margin: 0 0 20px;
            }
    
            .adjust-firm-points-modal__body__filters .react-datepicker-wrapper,
            .adjust-firm-points-modal__body__filters .form-input,
            .adjust-firm-points-modal__body__filters .form-select,
            .adjust-firm-points-modal__body__filters .form-select-checkboxes  {
                display: inline-table;
                width: 15%;
                margin: 0 2% 0 0;
                box-sizing: border-box;
            }
    
            .adjust-firm-points-modal__body__filters .form-select:last-child {
                margin: 0;
            }
    
            .adjust-firm-points-modal__body__filters .form-input__label,
            .adjust-firm-points-modal__body__filters .form-select-checkboxes__label ,
            .adjust-firm-points-modal__body__filters .form-select__label {
                display: block;
            }
    
            .adjust-firm-points-modal__body .data-table .form-select-checkboxes  {
                margin: 0;
            }
    
            .adjust-firm-points-modal__body .data-table .form-input {
                width: 100px;
                margin: 0;
            }
    
            .adjust-firm-points-modal__body .data-table .form-input input {
                width: 100%;
            }
    
            .adjust-firm-points-modal__body .data-table .form-select {
                margin: 0;
            }
     
            .adjust-firm-points-modal__body .data-table td:last-child .table-cell {
                flex-direction: column;
            }

            .adjust-firm-points-modal__body .data-table td:last-child .table-cell .form-input {
                width: 100%;
            }

            .adjust-firm-points-modal__body .data-table .custom-reason-link {
                color: var(--clr-darkblue);
                margin: 5px 0;
                font-size: 12px;
                cursor: pointer;
            }
    
        .adjust-firm-points-modal__footer {
            background-color: var(--clr-lightgrey);
            padding: 14px 16px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    
            .adjust-firm-points-modal__footer button {
                margin-left: 10px;
            }

/* ****************************************************************************************** */
/* *************************************** FIRM RATES *************************************** */
/* ****************************************************************************************** */

.firm-details-rates {
    gap: 22px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

    .firm-details-rates__select-container {
        display: flex;
        gap: 10px;
    }

        .firm-details-rates__select-container__select {
            max-width: 180px;
        }

        .firm-details-rates .document-uploader__uploaded-documents-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

    .firm-details-rates__documents-title {
        font-weight: bold;
        font-size: 14px;
        color: var(--clr-darkgrey);
    }

    .firm-details-rates__upload-btn {
        max-width: 100px;
    }

/* ****************************************************************************************** */
/* ************************************* FIRM FAMILIES ************************************** */
/* ****************************************************************************************** */

.firm-families-popup-list {
    width: 400px;
    height: 250px;
    overflow-y: auto;
}

    .firm-families-popup-list__item {
        width: 100%;
        border-bottom: 1px solid var(--clr-lightgrey);
        font-size: 14px;
        padding: 15px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        border-radius: var(--radius-small);
    }

    .firm-families-popup-list__item:hover {
        background-color: var(--clr-lightblue);
    }

/* ****************************************************************************************** */
/* ************************************** FIRM MEMBERS *************************************** */
/* ****************************************************************************************** */

.firm-details-members {
    /* gap: 44px; */
    display: flex;
    width: 100%;
    flex-direction: column;
}

    .firm-details-members__section-header {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    .firm-details-members__section-header.filters {
        margin-top: 20px;
    }

    .firm-details-members__section-header-filters-archived {
        display: flex;
        padding: 10px;
        margin-top: 10px;
    }
    
        .firm-details-members__section-header-filters-archived ul {
            display: flex;
            margin-left: 30px;
        }
    
        .firm-details-members__section-header-filters-archived ul li{
            margin: 0 5px;
            color: rgb(185,188,182);
            text-decoration: none;
            font-size: 14px;
            cursor: pointer;
        }
    
        .firm-details-members__section-header-filters-archived ul li.active{
            color: var(--clr-azami-blurple);
            font-weight: bold;
        }

    .firm-details-members__section-header__add-btn {
        height: 37px;
        margin-top: 5px;
    }

    .firm-details-members__section-body {
        margin-top: 15px;
        width: 100%;
        /* max-width: 50%; */
    }

        .firm-details-members__section-body__full-name {
            color: var(--clr-black) !important;
        }

    .firm-details-members__section-footer {
       text-align: center;
    }

/* ****************************************************************************************** */
/* ************************************** FIRM ACCOUNTS ************************************* */
/* ****************************************************************************************** */

.firm-details-accounts {
    /* gap: 44px; */
    display: flex;
    width: 100%;
    flex-direction: column;
}

    .firm-details-accounts__section-header {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0;
    }

        .firm-details-accounts__section-header__title {
            display: flex;
            color: var(--clr-darkgrey);
            font-size: 16px;
            font-weight: bold;
            align-self: flex-end;
        }

    .firm-details-accounts__section-body {
        width: 100%;
        margin-top: 15px;
    }

        .accounts-table__action-points-btn,
        .accounts-table__action-edit-btn,
        .accounts-table__action-delete-btn,
        .account-users-table__action-delete-btn {
            cursor: default;
            background-repeat: no-repeat;
            background-color: transparent;
            border: none;
            border-radius: 0;
            width: 19px;
            height: 17px;
            display: inline-block;
            cursor: pointer;
            margin-left: 10px
        }

        .accounts-table__action-points-btn {
            background-image: url(../../../assets/images/icons/ico_btn_add_lightgrey.svg);
        }

        .accounts-table__action-edit-btn {
            background-image: url(../../../assets/images/icons/ico_btn_edit_lightgrey.svg);
        }

        .accounts-table__action-delete-btn,
        .account-users-table__action-delete-btn {
            background-image: url(../../../assets/images/icons/ico_btn_delete_lightgrey.svg);
        }
        .account-users-table__action-delete-btn:disabled {
            cursor: not-allowed;
        }

        .account-users {
            margin: 20px 0;
            overflow-y: auto;
            position: relative;
            width: 100%;
            max-height: 300px;
        }
        
        .account-users .data-table tr.fixed-header > th {
            top: 0px;
        }

        .account-users-table-errors {
            font-size: 12px;
            color: var(--clr-red);
            display: block;
        }

        .account-users__custom-radio-btn {
            margin-bottom: 0;
            margin-top: 0;
        }

        .account-users-section__adjustments {
            display: flex;
            justify-content: flex-end;
        }

        /* ******************************************************************************************** */
        /* ********************************* FIRM ACCOUNT COLLAPSIBLE ********************************* */
        /* ******************************************************************************************** */
        .firm-account-collapsible {
            margin-bottom: 10px;
        }

            .firm-account-collapsible__header {
                display: flex;
                justify-content: space-between;
                background-color: var(--clr-background);
                padding: 5px 7px 5px 10px;
                align-items: center;
                border-radius: var(--radius-small);
                height: 34px;
                cursor: pointer;
            }

            .firm-account-collapsible.active .firm-account-collapsible__header {
                background-color: var(--clr-azami-blurple);
            }

                .firm-account-collapsible__header__title {
                    display: flex;
                    align-items: center;
                }

                    .firm-account-collapsible__header img {
                        margin-right: 5px;
                    }

                    .firm-account-collapsible__header__name, .firm-account-collapsible__header__users-count{
                        font-size: 14px;
                        font-weight: bold;
                        color: var(--clr-azami-blurple);
                        margin-right: 5px;
                    }

                    .firm-account-collapsible.active .firm-account-collapsible__header__name {
                        color: var(--clr-white);
                    }

                    .firm-account-collapsible__header__users-count {
                        background-color: var(--clr-azami-blurple);
                        color: var(--clr-white);
                        padding: 4px;
                        border-radius: 5px;
                    }
                    
                    .firm-account-collapsible.active .firm-account-collapsible__header__users-count {
                        background-color: var(--clr-white);
                        color: var(--clr-darkblue);
                    }

                .firm-account-collapsible__header__actions {
                    display: flex;
                    align-items: center;
                }

                    .firm-account-collapsible__action-points-btn,
                    .firm-account-collapsible__action-edit-btn,
                    .firm-account-collapsible__action-delete-btn {
                        cursor: default;
                        background-repeat: no-repeat;
                        background-color: transparent;
                        border: none;
                        border-radius: 0;
                        width: 19px;
                        height: 17px;
                        display: inline-block;
                        cursor: pointer;
                        margin-left: 10px
                    }

                    .firm-account-collapsible__action-points-btn {
                        background-image: url(../../../assets/images/icons/ico_btn_add_darkblue.svg);
                    }

                    .firm-account-collapsible__action-edit-btn {
                        background-image: url(../../../assets/images/icons/ico_btn_edit_darkblue.svg);
                    }

                    .firm-account-collapsible__action-delete-btn {
                        background-image: url(../../../assets/images/icons/ico_btn_delete_darkblue.svg);
                    }

                    .firm-account-collapsible.active .firm-account-collapsible__action-points-btn {
                        background-image: url(../../../assets/images/icons/ico_btn_add_white.svg);
                    }

                    .firm-account-collapsible.active .firm-account-collapsible__action-edit-btn {
                        background-image: url(../../../assets/images/icons/ico_btn_edit_white.svg);
                    }

                    .firm-account-collapsible.active .firm-account-collapsible__action-delete-btn {
                        background-image: url(../../../assets/images/icons/ico_btn_delete_white.svg);
                    }

                .firm-account-collapsible__body {
                    display: flex;
                    flex-direction: column;
                    margin-top: 15px;
                    padding: 0 10px;
                    gap: 44px;
                }

                    .account-users-section {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }

                        .account-users-section__header {
                            display: flex;
                            font-size: 14px;
                        }

                            .account-users-section__header .title {
                                flex-grow: 1;
                                font-weight: bold;
                                font-size: 16px;
                            }

                            .account-users-section__header img {
                                width: 10px;
                                margin: 0 3px;
                            }

                        .account-users-section__list {
                            display: flex;
                            flex-wrap: wrap;
                            font-size: 14px;
                        }

                            .account-users-section__list-item {
                                display: flex;
                                align-items: center;
                                background-color: var(--clr-table-header);
                                padding: 5px 10px;
                                margin: 5px 5px 0 0;
                                border-radius: 5px;
                            }

                            .account-users-section__list-item.dark {
                                background-color: var(--clr-darkblue);
                                color: var(--clr-white);
                            }

                                .account-users-section__list-item .avatar {
                                    width: 25px;
                                    height: 25px;
                                    border-radius: 50%;
                                    margin-right: 10px;
                                    object-fit: cover;
                                }
                        
                                    .account-users-section__list-item .avatar img {
                                        width: 25px;
                                        height: 25px;
                                        border-radius: 50%;
                                        object-fit: cover;
                                    }

                    .account-ratios-section {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }

                        .account-ratios-section__header {
                            display: flex;
                            font-size: 14px;
                        }

                            .account-ratios-section__header .title {
                                flex-grow: 1;
                                font-weight: bold;
                                font-size: 16px;
                            }

                            .account-ratios-section__header img {
                                width: 10px;
                                margin-right: 3px;
                            }

                        .account-ratios-section__base-points {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            padding-top: 10px;
                        }

                            .account-ratios-section__base-points__header {
                                display: flex;
                                font-weight: bold;
                                font-size: 14px;
                            }

                                .account-ratios-section__base-points__header .title {
                                    flex-grow: 1;
                                }

                        .account-ratios-section__reward-points {
                            display: flex;
                            flex-direction: column;
                            gap: 15px;
                            padding-top: 10px;
                        }

                            .account-ratios-section__reward-points span {
                                font-weight: bold;
                                font-size: 14px;
                            }
