.cases-sidebar{
    width: 300px;
    height: 100%;
    padding: 0 0 115px 15px;
    /* position: fixed; */
    overflow-y: auto;
}

.cases-sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}
 
    .cases-sidebar__header img {
        margin-right: 5px;
    }

.cases-sidebar__deadline-label {
    font-size: 13px;
    margin-left: 5px;
    margin-bottom: 8px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--theme-clr-base-dark)
}

.cases-sidebar__deadline-range {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

    .deadline-range__icon {
        padding-top: 15px;
    }
    
.cases-list__data-table tr {
    cursor: pointer;
}






