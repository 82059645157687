.reports-sidebar {
    width: 300px;
    padding: 0 0 115px 15px;
    height: 100%;
    /* top: 0px; */
    /* position: fixed; */
    overflow-y: auto;
}

.reports-sidebar__header {
    color: var(--clr-midgrey);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}

    .reports-sidebar__header img {
        margin-right: 5px;
    }

.reports-sidebar_actions {
    padding-top: 25px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--clr-midgrey);
}

.reports-sidebar_hgroup {
    display: flex;
    justify-content: space-between;
}

.reports-sidebar_hgroup label:first-child, 
.reports-sidebar_hgroup label:last-child {
    width: 48%;
}

.main-screen-section__header-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px 0 20px;
    gap: 10px;
}

.case-report .adjust {
    cursor: default;
    background-image: url(../../../assets/images/icons/edit.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 19px;
    height: 17px;
    display: inline-block;
    cursor: pointer;
}

.adjustment-row {
    background-color: var(--clr-adjustment-table-row);
}