.back-button {
    display: flex;
    align-items: center;
    color: var(--theme-clr-base-mid3);
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    transition: all .3s ease-in-out;
    padding: 20px;

    .arrows {
        transition: all .3s ease-in-out;
        font-weight: 100;
        color: var(--theme-clr-base-light);
        font-size: 30px;
        padding: 0 5px 4px 5px;
        border-radius: 5px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &.primary {
            background-color: var(--theme-clr-primary);
        }

        &.secondary {
            background-color: var(--theme-clr-secondary);
        }
    }

    &:hover .arrows {
        margin-right: 15px;
    }
}